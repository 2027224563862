import React from 'react';
import {motion} from 'framer-motion';
import {graphql} from 'gatsby';
import {PageHeader} from '../components/Headings';
import QuoteBlock from '../components/QuoteBlock';
// import StaffSmallBlock from "../components/StaffSmallBlock";
import Layout from '../components/Layout';
import {ButtonPrimary} from '../components/Button';
import {pageVariants, pageTransition} from '../components/Transitions';
import ContactForm from '../components/ContactForm';

function Contact({data}) {
  const {
    seomatic,
    heading,
    body,
    blockquote: [blockquote],
  } = data.craftContactUsContactUsEntry;
  return (
    <Layout seomatic={seomatic} preloadFooter>
      <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition} className="contact font-display">
        <PageHeader heading={heading} subheading={body} />
        <div className="bg-white">
          <div className="max-w-screen-xl m-auto w-full pl-6 pr-6 pt-8 pb-4 lg:pb-16 lg:pt-12 lg:flex">
            <div className="flex-auto lg:pr-12 lg:mr-12 lg:border-r border-gray-200 border-opacity-80">
              <ContactForm />
            </div>
            {blockquote && (
              <div className="mt-8 lg: mt-0">
                <QuoteBlock quote={blockquote.quote} byline={blockquote.caption} />
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </Layout>
  );
}

export const query = graphql`
  query ContactQuery {
    craftContactUsContactUsEntry {
      seomatic {
        ...SeomaticFields
      }
      heading
      body
      blockquote {
        ... on Craft_blockquote_quote_BlockType {
          id
          caption
          quote
        }
      }
    }
  }
`;

export default Contact;
